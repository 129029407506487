<template>
  <div>
    <b-modal modal-class="cropperDialog" v-model="vmCropImageModal" :size="propCropModalSize ? propCropModalSize : 'md'" centered scrollable title="Crop Image" no-close-on-backdrop no-close-on-esc >
      <b-container style="width: 100% !important; max-width: unset;">
        <b-row>
          <b-col cols="12" style="max-height: 300px;">
            <cropper
              style="height: 300px; width: 100%;"
              ref="cropper"
              :src="getImgSrc"
              :auto-zoom="false"
              :stencil-size="propStencilSize ? propStencilSize : {
                'width': 100,
                'height': 100
              }"
              image-restriction="stencil"
              @change="change"
              :checkOrientation="checkOrientation"
              @ready="ready"/>
          </b-col>
          <b-col cols="12" class="mt-4">
            <b-button variant="primary" class="pull-left" @click="zoomIn(1.2)">
              <i class="fa fa-search-plus ml-1 font-size-16"></i>
            </b-button>
            <b-button variant="primary" class="ml-1 pull-left" @click="zoomIn(0.8)">
              <i class="fa fa-search-minus ml-1 font-size-16"></i>
            </b-button>
            <b-button variant="primary" class="pull-right" @click="rotate(90)">
              <i class="fa fa-rotate-right ml-1 font-size-16"></i>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="">
        <!--        <b-button size="sm" class="pull-left" variant="secondary" @click="closeCropImageModal()">-->
        <!--          Skip-->
        <!--        </b-button>-->
        <b-button size="sm" variant="primary" @click="saveImage()" :disabled="submitting">
          <b-spinner small class="ml-4 mr-4" v-if="submitting" label="Spinning">
          </b-spinner>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>
.cropperDialog .modal-dialog{
   width:100%;
   margin:auto;
 }
</style>

<script>
/*eslint-disable */
import {Cropper} from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "crop_image_dialog",
  components: {
    Cropper
  },
  props:['propCropImageDialogOpen', 'propSrc', 'propCropModalSize', 'propStencilSize'],
  data: () => ({
    img:'',
    canvas:false,
    checkOrientation:true,
    imageFile:{},
    cropperShow:true,
    imageLoader:false,
    base64Image:'',
    getImgSrc: '',
    snackbar:false,
    err_msg:'',
    snackbar_color:'',
    vmCropImageModal: false,
    submitting: false,
    orgFile: {}
  }),
  computed: {

  },
  mounted() {
  },
  methods: {
    /**
     * closeCropImageModal
     */
    closeCropImageModal () {
      this.vmCropImageModal = false
    },
    /**
     * change
     *
     */
    change(e) {
      if(e.canvas){
        this.imageLoader = true;
        let canvas = e.canvas;
        this.img= canvas.toDataURL(this.orgFile.type);
        this.imageFile = this.convertCanvasImageToFile(canvas.toDataURL(this.orgFile.type));
        this.imageLoader = false;
      }

    },
    /**
     * convertCanvasImageToFile
     *
     */
    convertCanvasImageToFile(dataURL){
      let blobBin = atob(dataURL.split(',')[1]);
      let array = [];
      for(let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: this.orgFile.type});
    },
    /**
     * doneCropImage
     *
     */
    saveImage(){
      this.$emit('emitCroppedImg', this.img)
      this.$emit('emitCroppedImgFile', {'imgFile': this.imageFile, 'orgImage': this.orgFile})
      this.vmCropImageModal = false;
    },
    /**
     * ready
     *
     */
    ready(){
      this.imageLoader = false;
    },
    /**
     * zoom
     */
    zoomIn(val) {
      this.$refs.cropper.zoom(val);
    },
    zoomOut() {
      this.$refs.cropper.zoomOut(2);
    },
    /**
     * Rotate Image
     * @param angle
     */
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    /**
     * createBase64Image
     *
     */
    createBase64Image(){
      let reader = new FileReader();
      reader.onload = (e) =>{
        this.base64Image = e.target.result;
      };
      reader.readAsDataURL(this.propSrc);
    },
    /**
     * bytesToSize
     *
     */
    bytesToSize(bytes) {
      return Math.round(bytes / Math.pow(1024, 2), 2);
    },
    /**
     * compress
     *
     */
    compress(imageSrc, fileName) {
      let keepAspectRatio = true;
      let compressPercentage = ( 100 - 20 );
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = imageSrc;

        img.onload =  () => {
          const elem = document.createElement('canvas');
          let width = (img.width * compressPercentage)/100;
          let height = (img.height * compressPercentage)/100;

          if(keepAspectRatio){
            const scaleFactor = width / img.width;
            height = img.height * scaleFactor;
          }

          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext('2d');

          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
            const file = new File([blob], fileName, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });

            resolve({
              file:file,
              url:URL.createObjectURL(file)
            });
          }, 'image/jpeg', 1);
        };

        img.onerror = reject;
      })
    }
  },/*method closure */
  watch: {
    /**
     * src
     */
    propSrc: async function (src) {
      if(src){
        let fileSize = this.bytesToSize(src.size);

        if(fileSize > process.env.VUE_APP_FILE_IMAGE_SIZE_LIMIT ){
          this.snackbar = true;
          this.err_msg = 'Image size should be less then '+process.env.VUE_APP_FILE_IMAGE_SIZE_LIMIT+'MB';
          this.snackbar_color='error';
          this.dialog=false;
          return false;
        }
        this.orgFile = src
        if(fileSize <= 1){
          //1 in MB if image size already small and small then 2 MB then image will not be compressed
          this.getImgSrc =  URL.createObjectURL(src);
          this.imageFile = src;
        }
        else{
          let imgSrc =  URL.createObjectURL(src);
          let compressed_file = await this.compress(imgSrc, src.name);
          this.getImgSrc  = compressed_file.url;
          this.imageFile  = compressed_file.file;
        }
      }
    },
    propCropImageDialogOpen () {
      this.vmCropImageModal = true
    }
  }
}
</script>
