<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link" :class="!openAbout ? 'active m-0' : ''" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0">
                <a class="nav-link" :class="openAbout ? 'active' : ''" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <iq-card>
        <div class="iq-edit-list-data">
          <div class="tab-content">
            <div class="tab-pane fade" :class="!openAbout ? 'active show' : ''" id="profile" role="tabpanel">
              <b-card class="mb-4">
                <ValidationObserver ref="pbaForm1">
                  <b-form class="">
                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="mb-3">
                        {{cvPBANameLabel}}
                      </h5>
                      <ValidationProvider
                        name="Activity Name"
                        rules="required"
                        v-slot="{ errors }">
                        <input v-model="vmPBAFormData.event_name" :placeholder="cvPBANameLabel" type="text" class="form-control" required  />
                        <span class="text-danger">
                          <span v-if="vmPBAFormData.event_name && errors[0]"> {{ cvNameLimitErr }} </span>
                          <span v-else> {{ errors[0] }} </span>
                        </span>
                      </ValidationProvider>

                      <ValidationProvider
                        v-if="vmPBAFormData.event_hosting_platform === eventHostingPlatformConfig.ZOOM && vmPBAFormData.event_finished === 1"
                        name="Zoom Event Meeting Id"
                        v-slot="{ errors }">
                        <input v-model="vmPBAFormData.event_meeting_id" placeholder="Zoom Event Meeting Id" type="text" class="form-control mt-4" />
                        <span class="text-danger">
                          <span> {{ errors[0] }} </span>
                        </span>
                      </ValidationProvider>

                      <div class="mt-4">
                        <b-btn type="button" variant="primary" @click="eventEdit(true)" :disabled="cvLoadingStatus || cvImageLoadingStatus || cvImageError">
                          <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus || cvImageLoadingStatus" h5="Spinning">
                          </b-spinner>
                          <span v-else>{{cvPBASaveBtn}}</span>
                        </b-btn>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5>Objective:&nbsp;<span style="color:#e5252c;">{{ vmPBAFormData.event_hosting_platform }}</span></h5>
                    </div>

                    <div class="mt-4 pointer">
                      <h5>Event / Activity Public View Link:</h5>
                      <div>
                        1) Long Form Link: <span style="color:#e5252c;" @click="openPublicLink(eventPublicViewLinkV2)">{{ eventPublicViewLinkV2 }}</span><br>
                        2) Short Form Link: <span style="color:#e5252c;" @click="openPublicLink(eventPublicViewLinkV2SF)">{{ eventPublicViewLinkV2SF }}</span><br>
                        3) B2B Form Link: <span style="color:#e5252c;" @click="openPublicLink(eventPublicViewLinkV2B2B)">{{ eventPublicViewLinkV2B2B }}</span>
                      </div>
                    </div>

                    <div class="mt-4">
                      <h5 class="mb-3">
                        {{ cvPBACoverPic }}
                      </h5>
                      <div class="form-group row align-items-center">
                        <div class="col-md-12">
                          <div class="profile-img-edit border" style="height: 100%; width: 100%; max-width:300px">
                            <img v-if="vmCoverPic" :src="vmCoverPic" alt="cover-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                            <i v-else @click="uploadClick()" class="fas fa-image d-flex justify-content-center align-items-center my-5" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                            <h5 class="p-image" @click="uploadClick()">
                              <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                              <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                            </h5>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="p-2 rounded btn btn-primary border-0 mb-2 mr-2"  :disabled="cvCoverPicError || cvCoverPicLoading" @click="imageEdit('cover_pic')">
                        <b-spinner small class="ml-4 mr-4" v-if="cvCoverPicLoading" h5="Spinning">
                        </b-spinner>
                        <span v-else>Save Profile Pic</span>
                      </button>
                      <button v-if="vmCoverPic" type="button" class="p-2 rounded btn btn-primary border-0 mb-2" :disabled="cvCoverPicError || cvCoverPicLoading"  @click="imageRemove('cover_pic')">
                        <b-spinner small class="ml-4 mr-4" v-if="cvDelCoverPicLoading" h5="Spinning">
                        </b-spinner>
                        <span v-else>Remove Profile Pic</span>
                      </button>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-card>
            </div>
            <div class="tab-pane fade" id="about" role="tabpanel" :class="openAbout ? 'active show' : ''">
              <b-card class="mb-4 p-3">
                <ValidationObserver ref="pbaForm">
                  <b-form>
                    <div class="col-md-12 mb-4 pl-0" v-if="vmPBAFormData.event_meeting_params && !vmPBAFormData.event_finished">
                        <b-button variant="primary" @click="utility.openUrlInNewTab(vmPBAFormData.event_meeting_params.start_url)">
                          Start Meeting
                        </b-button>
                        <b-button class="ml-3" variant="primary" @click="utility.openUrlInNewTab(vmPBAFormData.event_meeting_params.join_url)">
                          Join Meeting
                        </b-button>
                    </div>

                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="mb-3">
                        {{cvAbout}}<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <!-- rules="required|max:500" -->
                      <ValidationProvider
                        name="Activity Description"
                        rules="required"
                        v-slot="{ errors }">
                        <vue-editor v-model="vmPBAFormData.event_desc"></vue-editor>
                        <span class="text-danger">
                          <span v-if="vmPBAFormData.event_desc && errors[0]"> {{cvDescLimitErr}} </span>
                          <span v-else> {{ errors[0] }} </span>
                        </span>
                      </ValidationProvider>
                    </div>

                    <div v-if="vmPBAFormData.event_hosting_platform != eventHostingPlatformConfig.ZOOM" class="col-md-12 mb-4 mt-4 pl-0">
                      <h4 class="mb-3">
                        Event Specific Link
                      </h4>
                      <ValidationProvider
                        name="Event Web Link"
                        v-slot="{ errors }">
                        <input v-model="vmPBAFormData.event_weblink" :placeholder="cvPBALinkLabel" type="text" class="form-control" />
                        <span class="text-danger">
                          <span> {{ errors[0] }} </span>
                        </span>
                      </ValidationProvider><br>

                      <h5>Event / Activity Public View Link</h5>
                      <span>{{ eventPublicViewLink }}</span>
                    </div>

                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="p-0 mb-2">{{cvPBATypeLabel}}<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <multiselect
                        v-model="vmPBAFormData.event_type"
                        :options="cvPBATypeList"
                        label="event_type_name"
                        track-by="event_type_id">
                      </multiselect>
                    </div>

                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="p-0 mb-2">{{cvPBALevelLabel}}<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <multiselect
                        v-model="vmPBAFormData.event_level"
                        :options="cvPBALevelList"
                        label="event_lev_name"
                        track-by="event_lev_id">
                      </multiselect>
                    </div>

                    <div class="row pl-2 pt-4 pb-4">
                      <h5 class="w-100 pb-2">
                          Schedule Your Event<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>

                      <div class="col-md-5 mb-4 pl-0">
                        <h5 for="pba-start-datepicker">
                          Start date
                        </h5>
                        <datetime
                          type="datetime"
                          v-model="vmStartDate"
                          input-class="datetime_input"
                          class="theme-primary"
                          :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'}"
                          :phrases="{ok: 'Continue', cancel: 'Exit'}"
                          :hour-step="1"
                          :minute-step="5"
                          :week-start="7"
                          use12-hour
                          auto
                          :min-datetime="minStartDate"></datetime>
                      </div>
                      <div class="col-md-5 mb-4 pl-0">
                        <h5 for="pba-end-datepicker">
                          End date
                        </h5>
                        <datetime
                          type="datetime"
                          v-model="vmEndDate"
                          input-class="datetime_input"
                          class="theme-primary"
                          :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'}"
                          :phrases="{ok: 'Continue', cancel: 'Exit'}"
                          :hour-step="1"
                          :minute-step="5"
                          :week-start="7"
                          use12-hour
                          auto
                          :min-datetime="vmStartDate ? vmStartDate : minStartDate"></datetime>
                        <span class="text-danger">
                          <span v-if="vmStartDate > vmEndDate"> {{ cvEndDateErr }} </span>
                        </span>
                      </div>
                      <div class="col-md-2 mb-4 pl-4">
                        <h5 for="pba-start-datepicker">
                            Duration
                        </h5>
                        <p class="ml-0 pt-2" v-if="vmStartDate && vmEndDate"> {{utility.getDiffDateTime(vmStartDate, vmEndDate)}}
                        </p>
                      </div>
                    </div>

                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="p-0 mb-2">Tag University / Organisation<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <multiselect
                        v-model="vmSelectedMentions"
                        :options="cvMentionableList"
                        track-by="id"
                        label="name"
                        multiple
                        tag-placeholder="Tag">
                      </multiselect>
                    </div>
                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="p-0 mb-2">Interests<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <multiselect
                        v-model="vmSelectedInterests"
                        :options="interestObjList"
                        track-by="interest_id"
                        label="interest_name"
                        multiple
                        tag-placeholder="Interest">
                      </multiselect>
                    </div>
                    <div class="col-md-12 mb-4 pl-0">
                      <h5 class="p-0 mb-2">Participant Type<label class="primary-color font-size-16" style="line-height: 0px;">*</label>
                      </h5>
                      <multiselect
                        v-model="vmSelectedUserRole"
                        :options="userRoleListObj"
                        track-by="user_role_id"
                        label="user_role_name"
                        multiple
                        tag-placeholder="Activity Participant Type">
                      </multiselect>
                    </div>

                    <template v-if="userData.user_role === 'USERROLE11111' || userData.user_role === 'USERROLE11118'">
                      <!-- Only for Admin -->
                      <div class="col-md-12 mb-4 pl-0">
                        <label class="p-0 mb-2">
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="vmPBAFormData.event_is_paid"
                            name="checkbox-1"
                            :value="1"
                            :unchecked-value="0"
                          >
                            Paid Event
                          </b-form-checkbox>
                        </label>
                        <label class="p-0 mb-2 ml-3">
                          <b-form-checkbox
                            id="checkbox-2"
                            v-model="event_is_affiliate"
                            name="checkbox-2"
                            :value="1"
                            :unchecked-value="0"
                          >
                            Do you want to associate an Affiliate to this event ?
                          </b-form-checkbox>
                        </label>
                      </div>

                      <div class="row w-100 pl-2" v-if="vmPBAFormData.event_is_paid">
                        <template v-if="eventPlans && eventPlans.length">
                          <div class="row w-100 mb-4">
                            <div class="planBox col-md-6 col-md-6 col-sm-12" v-for="(eventPlan, index) of eventPlans" :key="index+eventPlan.subs_plan_id">
                              <b-badge class="pull-right pointer" variant="primary" @click="deletePlan(eventPlan, index)">Delete</b-badge>
                              Plan Name: {{eventPlan.subs_plan_name}} <br>
                              Plan Id: {{eventPlan.subs_plan_id}} <br>
                              Plan Price: {{eventPlan.subs_plan_price}} <br>
                              Gide Member Price: {{eventPlan.subs_plan_price_gideplus}} <br>
                              Plan For: {{userRolesJson[eventPlan.subs_plan_allowed_role]}}
                            </div>
                          </div>
                        </template>

                        <div class="row w-100">
                          <div v-for="(role, index) of vmSelectedUserRole" :key="index+'role'">
                            <b-button @click="showModelSubscriptionPlanAddModal(role.user_role_id)" :class="index >0 ? 'ml-3': ''">
                            Add {{role.user_role_name}} Plan
                            </b-button>
                          </div>
                        </div>

                        <h6 class="pt-1">
                          *If you already have active module/student/university/organisation plan then previous plan will be deleted and new plan will added
                        </h6>

                        <b-modal
                          v-if="showModelSubscriptionPlanAdd"
                          v-model="showModelSubscriptionPlanAdd"
                          scrollable no-close-on-backdrop no-close-on-esc
                          :title="cvAddModalHeader"
                          size="xl"
                          >
                          <SubscriptionPlanAdd
                            :propOpenedInModal="true" :propModuleName="modName"
                            :propModulePlanName="vmPBAFormData.event_name"
                            :propRoleId="selectedRoleId"
                            :propModuleObjId="vmPBAFormData.event_id" :propModuleDesc="eventDesc"
                            @emitCloseSubscriptionPlanAddModal="closeSubscriptionPlanAddModal" />
                          <template #modal-footer="">
                            <b-button size="sm" @click="closeSubscriptionPlanAddModal()">
                              Cancel
                            </b-button>
                          </template>
                        </b-modal>
                      </div>

                      <div class="col-md-12 mb-4 mt-4 pl-0">
                        <h5 class="p-0 mb-2">Allowed Registration Count</h5>
                        <ValidationProvider
                          name="Allowed Registration Count"
                          v-slot="{ errors }">
                          <input v-model="vmPBAFormData.event_rsvp_limit" :placeholder="cvRegLimitLabel" type="tel" class="form-control" />
                          <span class="text-danger">
                            <span> {{ errors[0] }} </span>
                          </span>
                        </ValidationProvider><br>
                      </div>

                      <div class="col-md-12 mb-4 pl-0" v-if="event_is_affiliate">
                      <h5 class="p-0 mb-2">Affiliated Organisations</h5>
                      <multiselect
                        v-model="vmPBAFormData.aff_entity_id"
                        :options="affiliateOrgsObjList"
                        label="org_name"
                        track-by="org_name">
                      </multiselect>
                    </div>
                    </template>

                    <div class="mt-4">
                      <b-btn type="button" variant="primary" class="pull-left" @click="eventEdit()" :disabled="cvLoadingStatus || cvImageLoadingStatus || cvImageError">
                        <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus || cvImageLoadingStatus" h5="Spinning">
                        </b-spinner>
                        <span v-else>{{cvPBASaveBtn}}</span>
                      </b-btn>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-card>
            </div>
          </div>
        </div>
      </iq-card>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<script>
import "vue-datetime/dist/vue-datetime.css"
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events"
import { Images } from "../../../FackApi/api/image"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import CropImage from "../../../components/cropImage.vue"
import Multiselect from "vue-multiselect"
import { Datetime } from "vue-datetime"
import { eventLevels } from "../../../FackApi/api/eventLevel"
import { eventTypes } from "../../../FackApi/api/eventType"
import { Interests } from "../../../FackApi/api/interest"
import moment from "moment"
import { Organisations } from "../../../FackApi/api/organisation"
import { Clubs } from "../../../FackApi/api/club"
import { UserRole } from "../../../FackApi/api/userRole"
import Utility from "../../../Utils/utility"
import { VueEditor } from "vue2-editor"
import eventHostingPlatformConfig from "../../../FackApi/json/EventHostingPlatforms.js"
import userRolesJson from "../../../FackApi/json/UserRoles.json"
import SubscriptionPlanAdd from "../Subscription/SubscriptionPlanAdd.vue"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import { Affiliates } from "../../../FackApi/api/Affiliate"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "eventEdit",
  components: {
    ValidationProvider,
    CropImage,
    Multiselect,
    Datetime,
    VueEditor,
    SubscriptionPlanAdd
  },
  data () {
    return {
      apiName: "event_edit",
      cvSelectLabel: "Select ",
      oldSessionPlanId: null,
      cvLoadingStatus: false,
      cvImageLoadingStatus: false,
      cvProfileInfo: "Activity",
      cvAbout: "Details",
      cvCardTitle: "Add Event",
      cvPBATypeLabel: "Activity Type",
      cvPBALevelLabel: "Activity Level",
      cvCardSubHeader: "",
      cvPBANameLabel: "Name",
      cvPBALinkLabel: "Zoom / Google meet / Website / Conference URL",
      cvPBSNameLabelSpan: "This will be visible only after RSVP",
      cvPBADescLabel: "About",
      cvPBACoverPic: "Event Profile Pic",
      cvRegLimitLabel: "Allowed Registration Limit",
      cvPBASaveBtn: "Save",
      cvDescLimitErr: "Activity description may not be more than 500 characters.",
      cvNameLimitErr: "Activity name may not be more than 50 characters.",
      cvLinkLimitErr: "Activity description may not be more than 250 characters.",
      showToast: false,
      showModelAskOtpBox: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event",
      cvImageError: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 300 kb.",
      vmPBAFormData: Object.assign({}, this.initPBAData()),
      vmPBACoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      pbaObj: {},
      propModuleName: "EVENT",
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      cvCoverPicError: false,
      cvCoverPicLoading: false,
      cvDelCoverPicLoading: false,
      cvPBATypeList: [],
      cvPBALevelList: [],
      openAbout: !!(this.$route.query && this.$route.query.about),
      minStartDate: "",
      maxStartDate: "",
      cvEndDateErr: "Start date may not be more than End date",
      vmStartDate: "",
      vmEndDate: "",
      vmStartTime: "",
      vmEndTime: "",
      cvMentionableList: [],
      interestObjList: [],
      affiliateOrgsObjList: [],
      vmSelectedMentions: [],
      vmSelectedInterests: [],
      userRoleListObj: [],
      vmSelectedUserRole: [],
      utility: Utility,
      eventHostingPlatformConfig: eventHostingPlatformConfig,
      showModelSubscriptionPlanAdd: false,
      cvAddModalHeader: "Add Subscription Plan",
      eventPlans: [],
      modName: "event",
      eventDesc: null,
      selectedRoleId: null,
      eventObj: {},
      event_is_affiliate: 0,
      userRolesJson: userRolesJson,
      // eventPublicViewLinkV1: `${process.env.VUE_APP_SERVER_URL}/contactUsV1?source=WEB&module_id={EVENT_ID}&module_name={NAME}`, // B2C Long Form Old. Deprecated
      eventPublicViewLinkV2: `${process.env.VUE_APP_SERVER_URL}/contactUs?source=WEB&module_id={EVENT_ID}&module_name={NAME}`, // B2C Long Form
      eventPublicViewLinkV2SF: `${process.env.VUE_APP_SERVER_URL}/contactUs?source=WEB&module_id={EVENT_ID}&module_name={NAME}&sf=1`, // B2C Short Form
      eventPublicViewLinkV2B2B: `${process.env.VUE_APP_SERVER_URL}/ContactUs?source=WEB&module_id={EVENT_ID}&module_name={NAME}&f=1` // B2B
    }
  },
  watch: {
    vmSelectedUserRole (newRole, oldRole) {
      const isUserRoleUnivAdmin = newRole.find(roleObj => roleObj.user_role_id === "USERROLE11115")

      // If the event participant type only contains the university admin the event_specific link will be of ContactUs instead of ContactUsForm
      if (isUserRoleUnivAdmin && newRole.length === 1) {
        this.vmPBAFormData.event_weblink = this.vmPBAFormData.event_weblink.replace("contactUsForm", "contactUs")
      }
      else {
        this.vmPBAFormData.event_weblink = this.vmPBAFormData.event_weblink_master
      }
    },
    event_is_affiliate (newValue) {
      if (newValue) {
        this.getAffOrgs()
      }
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)
    this.loadRequiredData()
  },
  methods: {
    /**
     * deletePlan
    */
    async deletePlan (plan, index) {
      const deletePlanApiResp = await SubscriptionPlans.subscriptionPlanDelete(this, plan.subs_plan_id)
      ApiResponse.responseMessageDisplay(this, deletePlanApiResp)
      if (deletePlanApiResp.resp_status) {
        this.eventPlans.splice(index, 1)
      }
    },
    /**
     * getAffOrgs
     */
    async getAffOrgs () {
      let affOrgs = await Affiliates.AffiliateListAffOrgs(this)
      if (affOrgs.resp_status) {
        this.affiliateOrgsObjList = affOrgs.resp_data.data
        this.vmPBAFormData.aff_entity_id = this.affiliateOrgsObjList.find(e => e.aff_entity_id == this.eventObj.aff_entity_id)
      }
    },
    /**
     * showModelSubscriptionPlanAddModal
    */
    showModelSubscriptionPlanAddModal (roleId) {
      if (this.vmPBAFormData.event_desc) {
        let a = document.createElement("div")
        a.innerHTML = this.vmPBAFormData.event_desc
        this.eventDesc = a.innerText
      }

      this.selectedRoleId = roleId
      this.oldSessionPlanId = this.eventPlans && this.eventPlans.length > 0 ? this.eventPlans[0].subs_plan_id : null
      this.showModelSubscriptionPlanAdd = true
    },
    /**
     * closeSubscriptionPlanAddModal
     */
    closeSubscriptionPlanAddModal (plan = null) {
      this.showModelSubscriptionPlanAdd = false
      if (plan && plan.id) {
        this.getEventPlans()
      }
      else {
        this.vmPBAFormData.event_is_paid = false
      }
    },
    /**
     * uploadClick
     */
    uploadClick () {
      let id = document.getElementById("fileInput1")
      if (id) {
        id.click()
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        event_name: "",
        event_desc: "",
        event_weblink: "",
        event_type: null,
        event_level: null,
        event_start_date: "",
        event_end_date: "",
        aff_entity_id: "",
        event_is_paid: 0,
        event_rsvp_limit: ""
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        module_id: this.$route.params.event_id,
        module_name: "EVENT",
        image: [],
        image_type: {}
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
      await this.eventView()
      await this.eventTypeList()
      await this.eventLevelList()
      await this.setMinMaxDate()
      await this.interestList()
      await this.organisationList()
      await this.clubList()
      await this.userRoleList()
    },
    /**
     * setMinMaxDate
     */
    setMinMaxDate () {
      if (this.vmPBAFormData.event_start_date) {
        this.vmStartDate = moment(this.vmPBAFormData.event_start_date).format()
      }
      else {
        this.vmStartDate = moment().format()
      }
      if (this.vmPBAFormData.event_end_date) {
        this.vmEndDate = moment(this.vmPBAFormData.event_end_date).format()
      }
      else {
        this.vmEndDate = moment(this.vmStartDate).add("1", "hours").format()
      }
      this.minStartDate = moment().format()
    },
    /**
     * eventView
     */
    async eventView () {
      try {
        let eventId = this.$route.params.event_id
        let eventViewResp = await events.eventView(this, eventId)

        if (eventViewResp && eventViewResp.resp_status) {
          this.vmPBAFormData = eventViewResp.resp_data.data

          if (this.vmPBAFormData.event_hosting_platform && this.vmPBAFormData.event_hosting_platform !== this.eventHostingPlatformConfig.ZOOM) {
            this.vmPBAFormData.event_weblink = this.vmPBAFormData.event_weblink_master
          }

          this.eventObj = { ...this.vmPBAFormData }

          // this.eventPublicViewLinkV1 = `${process.env.VUE_APP_SERVER_URL}/contactUsV1?source=WEB&module_id=${eventId}&module_name=${this.eventObj.event_name}` // B2C
          this.eventPublicViewLinkV2 = `${process.env.VUE_APP_SERVER_URL}/contactUs?source=WEB&module_id=${eventId}&module_name=${this.eventObj.event_name}` // B2C Long Form
          this.eventPublicViewLinkV2SF = `${process.env.VUE_APP_SERVER_URL}/contactUs?source=WEB&module_id=${eventId}&module_name=${this.eventObj.event_name}&sf=1` // B2C Short Form
          this.eventPublicViewLinkV2B2B = `${process.env.VUE_APP_SERVER_URL}/contactUs?source=WEB&module_id=${eventId}&module_name=${this.eventObj.event_name}&f=1` // B2B Form

          this.setMinMaxDate()
          this.getEventPlans()

          // Event Images
          if (eventViewResp.event_images && Object.keys(eventViewResp.event_images).length > 0) {
            this.vmCoverPic = eventViewResp.event_images[eventId]["cover_pic"]["image"]
          }

          // Event Hosting Platform
          if (eventViewResp.resp_data.data.event_hosting_platform) {
            this.vmPBAFormData.event_hosting_platform = eventViewResp.resp_data.data.event_hosting_platform
          }
          else {
            this.vmPBAFormData.event_hosting_platform = this.eventHostingPlatformConfig.OTHER
          }

          // Event type
          if (eventViewResp.event_type && Object.keys(eventViewResp.event_type).length > 0) {
            this.vmPBAFormData.event_type = eventViewResp.event_type
          }
          else {
            this.vmPBAFormData.event_type = null
          }

          // Event level
          if (eventViewResp.event_level && Object.keys(eventViewResp.event_level).length > 0) {
            this.vmPBAFormData.event_level = eventViewResp.event_level
          }
          else {
            this.vmPBAFormData.event_level = null
          }

          // Mentions
          if (eventViewResp.event_mentions && eventViewResp.event_mentions.length > 0) {
            for (let index in eventViewResp.event_mentions) {
              let mention = eventViewResp.event_mentions[index]
              if (mention.club_id) {
                this.vmSelectedMentions.push(
                  { name: `${mention.club_name} (Club)`, id: mention.club_id, "module_type": "CLUB", "module_data": mention }
                )
              }
              else if (mention.org_id) {
                this.vmSelectedMentions.push(
                  { name: `${mention.org_name} (${mention.type})`, id: mention.org_id, "module_type": `${mention.type}`, "module_data": mention }
                )
              }
            }
          }

          // Interests
          if (eventViewResp.event_interests && eventViewResp.event_interests.length > 0) {
            this.vmSelectedInterests = eventViewResp.event_interests
          }

          // Module type
          if (eventViewResp.event_allowed_role && eventViewResp.event_allowed_role.length > 0) {
            this.vmSelectedUserRole = eventViewResp.event_allowed_role
          }

          // Affiliation
          if (this.eventObj.aff_entity_id && this.eventObj.aff_entity_id.length > 5) {
            this.event_is_affiliate = 1
          }
        }
        else {
          this.$router.push(`/events`)
        }
      }
      catch (err) {
        console.error("Exception occurred at eventView() and Exception:", err.message)
      }
    },
    /**
     * getEventPlans
    */
    async getEventPlans () {
      // Event Paid
      if (this.vmPBAFormData.event_is_paid) {
        const subPlanResp = await SubscriptionPlans.subscriptionPlanListCustom(this, this.vmPBAFormData.event_id)

        if (subPlanResp.resp_status) {
          this.eventPlans = subPlanResp.resp_data.data
        }
      }
    },
    /**
     * eventEdit
     */
    async eventEdit (saveName = false) {
      try {
        if (saveName) {
          let valid = await this.$refs.pbaForm1.validate()
          if (!valid) {
            return
          }
        }
        else {
          let valid = await this.$refs.pbaForm.validate()
          if (!valid) {
            return
          }
        }

        let formData = this.vmPBAFormData
        if (saveName) {
          formData = {
            "event_id": this.vmPBAFormData.event_id,
            "event_name": this.vmPBAFormData.event_name
          }

          if (this.vmPBAFormData.event_hosting_platform === eventHostingPlatformConfig.ZOOM &&
            this.vmPBAFormData.event_finished === 1 && !this.eventObj.event_meeting_id) {
            formData.event_meeting_id = this.vmPBAFormData.event_meeting_id
          }
        }
        else {
          formData.event_start_date = this.vmStartDate
          formData.event_end_date = this.vmEndDate
          formData.formatted_date = moment.utc(this.vmStartDate).format("llll") + " - " + moment.utc(this.vmEndDate).format("llll") + " " + "(GMT)"
        }

        // If someone is mentioned
        if (this.vmSelectedMentions && this.vmSelectedMentions.length > 0) {
          let selectedMentions = []
          for (let index in this.vmSelectedMentions) {
            let mention = this.vmSelectedMentions[index]
            selectedMentions.push(
              {
                "id": mention.id,
                "module_type": mention.module_type
              }
            )
          }
          formData.mentions = JSON.stringify(selectedMentions)
        }
        else {
          formData.mentions = JSON.stringify([])
        }

        // If there are any interests selected
        if (this.vmSelectedInterests && this.vmSelectedInterests.length > 0) {
          let selectedInterests = []
          for (let index in this.vmSelectedInterests) {
            let interest = this.vmSelectedInterests[index]
            selectedInterests.push(
              {
                "interest_id": interest.interest_id,
                "interest_category_id": interest.interest_category_id
              }
            )
          }
          formData.interests = JSON.stringify(selectedInterests)
        }
        else {
          formData.interests = JSON.stringify([])
        }

        // Affiliation
        if (this.event_is_affiliate && this.vmPBAFormData.aff_entity_id) {
          formData.aff_entity_id = this.vmPBAFormData.aff_entity_id.aff_entity_id
        }
        else {
          formData.aff_entity_id = 0
        }

        // Event is paid or not
        if (this.vmPBAFormData.event_is_paid) {
          formData.event_is_paid = this.vmPBAFormData.event_is_paid
        }
        else {
          formData.event_is_paid = this.vmPBAFormData.event_is_paid
        }

        // Activity participant type
        if (this.vmSelectedUserRole && this.vmSelectedUserRole.length > 0) {
          formData.event_allowed_role = this.vmSelectedUserRole.map(obj => {
            return obj.user_role_id
          })
        }
        else {
          formData.event_allowed_role = "USERROLE11114" // Default
        }

        this.cvLoadingStatus = true
        let eventAddResp = await events.eventEdit(this, formData)
        await ApiResponse.responseMessageDisplay(this, eventAddResp)
        if (eventAddResp && !eventAddResp.resp_status) {
          return false
        }
        this.$router.push("/event_list")
      }
      catch (err) {
        console.error("Exception occurred at eventEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageEdit
     */
    async imageEdit (imageType) {
      if (imageType === "cover_pic" && this.vmPBACoverPicImage.image.length === 0) {
        return false
      }
      try {
        let image
        this.cvCoverPicLoading = true
        image = this.vmPBACoverPicImage
        const imageUpdateResp = await Images.imageProfileUpdate(this, image)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        let imageResp = imageUpdateResp.resp_data
        if (imageResp && imageResp.length > 0) {
          this.coverPicObj = imageResp[0]
        }
      }
      catch (err) {
        console.error("Exception occurred at imagEdit() and Exception:", err.message)
      }
      finally {
        this.cvCoverPicLoading = false
      }
    },
    /**
     * imageRemove
     */
    async imageRemove (imgType) {
      if (imgType === "profile_pic") {
        this.cvDelProfilePicLoading = true
      }
      else {
        this.cvDelCoverPicLoading = true
      }
      let img = imgType === "profile_pic" ? 2 : 3
      const imageUpdateResp = await Images.imageDelete(this, this.$route.params.event_id, img)
      await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
      if (imageUpdateResp && !imageUpdateResp.resp_status) {
        return false
      }
      if (imgType === "profile_pic") {
        this.vmProfilePic = null
        this.cvDelProfilePicLoading = false
      }
      else {
        this.vmCoverPic = null
        this.cvDelCoverPicLoading = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image.push(
        {
          "img": img,
          "name": orgImg.name
        }
      )
      this.vmPBACoverPicImage.image_type[orgImg.name] = 3
    },
    /**
     * eventLevelList
     */
    async eventLevelList () {
      try {
        let eventLevelListResp = await eventLevels.eventLevelList(this)
        if (eventLevelListResp && !eventLevelListResp.resp_status) {
          return false
        }
        this.cvPBALevelList = eventLevelListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at eventLevelList() and Exception:", err.message)
      }
    },
    /**
     * eventTypeList
     */
    async eventTypeList () {
      try {
        let eventTypeListResp = await eventTypes.eventTypeList(this)
        if (eventTypeListResp && !eventTypeListResp.resp_status) {
          return false
        }
        this.cvPBATypeList = eventTypeListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at eventTypeList() and Exception:", err.message)
      }
    },
    /**
     * interestList
     */
    async interestList () {
      try {
        let interestObj = {
          "page": this.page
        }
        let interestListResp = await Interests.interestList(this, interestObj)
        if (interestListResp.resp_status) {
          this.interestObjList = interestListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
    },
    /*
    * organisationList
    * */
    async organisationList () {
      const filter = {
        type: ["UNIV", "ORG"]
      }

      try {
        let organisationListResp = await Organisations.organisationList(this, filter)
        if (organisationListResp && organisationListResp.resp_status) {
          let organisationListObj = organisationListResp.resp_data.data
          if (organisationListObj) {
            for (let index in organisationListObj) {
              let org = organisationListObj[index]
              this.cvMentionableList.push(
                { name: `${org.org_name} (${org.type})`, id: org.org_id, "module_type": org.type, "module_data": org }
              )
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationList() and Exception:", err.message)
      }
    },
    /*
    * ClubList
    * */
    async clubList () {
      try {
        let clubListResp = await Clubs.clubList(this)
        if (clubListResp && clubListResp.resp_status) {
          let clubListObj = clubListResp.resp_data.data
          if (clubListObj) {
            for (let index in clubListObj) {
              let club = clubListObj[index]
              this.cvMentionableList.push(
                { name: `${club.club_name} (Club)`, id: club.club_id, "module_type": "CLUB", "module_data": club }
              )
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at clubList() and Exception:", err.message)
      }
    },
    /**
     * userRoleList
     */
    async userRoleList () {
      try {
        let userRoleListResp = await UserRole.userRoleList(this)
        if (userRoleListResp && userRoleListResp.resp_status) {
          this.userRoleListObj = userRoleListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at clubList() and Exception:", err.message)
      }
    },
    /**
     * open Public Link
     */
    openPublicLink (url) {
      window.open(url, "_blank")
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .planBox {
    border: 1px solid;
    padding: 10px;
  }
  .datetime_input {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    height: 45px;
    cursor: pointer;
    padding-left: 10px;
    color: #a09e9e;
    width: 100%;
  }
  .theme-primary .vdatetime-popup__header,
  .theme-primary .vdatetime-calendar__month__day--selected > span > span,
  .theme-primary .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #000000;
  }

  .theme-primary .vdatetime-year-picker__item--selected,
  .theme-primary .vdatetime-time-picker__item--selected,
  .theme-primary .vdatetime-popup__actions__button {
    color: #000000;
  }
  @media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
</style>
